import { Box, css, styled } from '@mui/material'

export const StyledProfile = styled(Box)`
  ${({ theme }) => css`
    background-color: #fff;
    border-radius: 24px;
    min-height: 243px;
    position: absolute;
    top: -97px;

    img {
      border: 2px dashed ${theme.palette.secondary.main};
      border-radius: 24px;
      width: 251px;
    }
  `}
`
