import { Divider, css, styled } from '@mui/material'

export const StyledDivider = styled(Divider)`
  ${({ theme, width }) => css`
    background-color: ${theme.palette.primary.main};
    border-bottom: 1px dashed #fff;
    border-radius: 8px;
    height: 3px;
    width: ${width}px ?? 100%;

    &.dashed-top {
      border-top: 1px dashed #fff;
      border-bottom: none;
    }
  `}
`
