import { Box, Container, Paper, css, styled } from '@mui/material'

export const HomeContainer = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: auto;

    ${theme.breakpoints.down('md')} {
      margin: 0;
    }
  `}
`

export const HomeContent = styled(Container)`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacing(5.5)};
    padding: ${theme.spacing(16.2, 3.75, 0)};

    ${theme.breakpoints.up('lg')} {
      padding: ${theme.spacing(16.2, 14, 0, 9.5)};
    }

    ${theme.breakpoints.down('md')} {
      flex-direction: column;
      gap: ${theme.spacing(7)};
      padding: ${theme.spacing(20.9, 0, 0)};
    }
  `}
`

export const LogoMobileCard = styled(Paper)`
  ${({ theme }) => css`
    border: 2px dashed #fff;
    border-radius: 24px 24px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 215px;
    padding: ${theme.spacing(0, 2)};
    width: 100%;

    ${theme.breakpoints.up('md')} {
      display: none;
    }
  `}
`
