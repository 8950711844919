import { Box, Chip, Paper, css, styled } from '@mui/material'

export const StyledSidebar = styled(Paper)`
  ${({ theme }) => css`
    border: 2px dashed #fff;
    border-radius: 24px;
    width: 370px;
    max-width: 100%;
    min-height: 757px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.spacing(21.3, 3, 5)};
    position: relative;

    .profile {
      background-color: #fff;
      border-radius: 24px;
      min-height: 243px;
      width: 251px;
      position: absolute;
      top: -97px;

      img {
        height: 100%;
        width: 100%;
        border: 1px dashed ${theme.palette.secondary.main};
        border-radius: 24px;
      }
    }

    .logo-desktop {
      position: absolute;
      top: -13px;
      right: -257px;
    }

    .contacts-divider {
      margin: ${theme.spacing(5, 0)};
      width: 100%;
    }

    ${theme.breakpoints.down('md')} {
      border-right: none;
      border-radius: 24px 0 0 24px;
      width: calc(100% - 30px);

      .logo-desktop {
        display: none;
      }
    }
  `}
`

export const StyledChip = styled(Chip)`
  ${({ theme }) => css`
    background-color: ${theme.palette.gray[200]};
    border-radius: 8px;
    color: ${theme.palette.gray[100]};
    height: 40px;
    padding: ${theme.spacing(1, 2)};
    ${theme.typography.body2};
  `}
`

export const StyledContacts = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.palette.gray[200]};
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    margin: ${theme.spacing(2, 0)};
    padding: ${theme.spacing(2.8, 3)};
    width: 100%;
  `}
`
