import { Link } from '@mui/material'
import { StyledIconButton } from './style'

const SocialLink = ({ link, icon }) => (
  <Link href={link} target='_blank'>
    <StyledIconButton>{icon}</StyledIconButton>
  </Link>
)

export default SocialLink
