import { Typography } from '@mui/material'
import { StyledContactItem } from './style'

const ContactItem = ({ icon, name, info }) => (
  <StyledContactItem>
    <div className='contact-icon'>{icon}</div>
    <div className='contact-content'>
      <Typography variant='caption'>{name}</Typography>
      <Typography variant='body2' color='#FFF' fontWeight={500}>
        {info}
      </Typography>
    </div>
  </StyledContactItem>
)

export default ContactItem
