import { Box, css, styled } from '@mui/material'

export const StyledServices = styled(Box)`
  ${({ theme }) => css`
    padding: ${theme.spacing(0, 3)};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(4)};

    .services-first-row {
      width: 77%;
    }

    .services-second-row {
      display: flex;
      gap: ${theme.spacing(4)};
      width: 100%;
    }

    ${theme.breakpoints.down('md')} {
      padding: 0;

      .services-first-row {
        width: 100%;
      }

      .services-second-row {
        flex-direction: column;
      }
    }
  `}
`
