import { ReactComponent as DesignUXIcon } from 'assets/design-ux.svg'
import { ReactComponent as WordPressIcon } from 'assets/wordpress.svg'
import { ReactComponent as InstructorIcon } from 'assets/instructor.svg'
import { ReactComponent as StoreIcon } from 'assets/store.svg'

const serviceList = [
  {
    title: 'UX/UI Design',
    icon: <DesignUXIcon />,
    description: (
      <>
        Pesquisa, criação de <b>Materiais Digitais</b>, design system, testes de
        usabilidade, prototipação, handoff
      </>
    )
  },
  {
    title: 'Desenvolvimento <> WordPress 👩‍💻',
    icon: <WordPressIcon />,
    description: <>Criação de sites e personalização de temas e plugins para WordPress</>
  },
  {
    title: 'Instrutor Frontend e de Carreira',
    icon: <InstructorIcon />,
    description: 'Ajudo a formar novos profissionais no meio digital'
  },
  {
    title: 'Lojas Virtuais Woocommerce ',
    icon: <StoreIcon />,
    description: 'Criação e configuração de lojas online'
  }
]

export default serviceList
