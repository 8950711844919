import { Paper, css, styled } from '@mui/material'

export const WrapPortfolioStyled = styled(Paper)`
  ${() => css`
    width: 127px;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    color: #fff;
    position: absolute;
    top: -62px;
    left: calc(50% - (127px / 2));

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      width: 100%;
      height: 76px;
      background: #3d3e3f;
      border-radius: 8px;
      text-decoration: none;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      flex-direction: column;
      transition: all 0.2s ease-in;

      :hover {
        background: #5845c2;
      }
    }
  `}
`

export const StyledAboutMe = styled(Paper)`
  ${({ theme }) => css`
    border: 2px dashed #fff;
    border-bottom: none;
    border-radius: 24px 24px 0px 0px;
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spacing(18.5)};
    padding: ${theme.spacing(8)};
    width: 100%;
    position: relative;

    .about-me-title {
      display: flex;
      align-items: center;
      gap: ${theme.spacing(3)};
      margin-bottom: ${theme.spacing(5)};
    }

    ${theme.breakpoints.down('md')} {
      border: none;
      border-top: 2px dashed #fff;
      margin-top: 56px;
      padding: ${theme.spacing(8, 3)};

      .about-me-title {
        flex-direction: column;
        align-items: flex-start;
        gap: ${theme.spacing(2)};
        margin-bottom: ${theme.spacing(2)};
      }
    }
  `}
`
