import { Stack } from '@mui/material'
import { ReactComponent as AlefSilva } from 'assets/alef-silva.svg'
import LogoDesktop from 'assets/logo-desktop.png'
import ProfileImage from 'assets/profile.png'
import { ReactComponent as WhatsappIcon } from 'assets/whatsapp.svg'
import Button from 'components/Button'
import Divider from 'components/Divider'
import contactList from 'constants/contactList'
import { socialLinkList, whatsappLink } from 'constants/socialLinkList'
import ContactItem from './ContactItem'
import Profile from './Profile'
import SocialLink from './SocialLink'
import { StyledChip, StyledContacts, StyledSidebar } from './style'

const Sidebar = () => (
  <StyledSidebar>
    <Profile image={ProfileImage} />
    <img src={LogoDesktop} alt='logo' className='logo-desktop' />
    <Stack gap={1}>
      <AlefSilva />
      <StyledChip label='Web Designer' />
      <Stack direction='row' justifyContent='center' gap={1}>
        {socialLinkList.map((item, index) => (
          <SocialLink key={index} {...item} />
        ))}
      </Stack>
    </Stack>
    <div className='contacts-divider'>
      <Divider />
      <StyledContacts>
        {contactList.map((item, index) => (
          <ContactItem key={index} {...item} />
        ))}
      </StyledContacts>
      <Divider dashedTop />
    </div>
    <Button endIcon={<WhatsappIcon />} component='a' href={whatsappLink} target='_blank'>
      Contratar
    </Button>
  </StyledSidebar>
)

export default Sidebar
