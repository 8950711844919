import { ReactComponent as EmailIcon } from 'assets/email.svg'
import { ReactComponent as LocationIcon } from 'assets/location.svg'
import { ReactComponent as WhatsappIcon } from 'assets/whatsapp.svg'

const contactList = [
  {
    name: 'WhatsApp',
    icon: <WhatsappIcon />,
    info: '+55 (11) 9 8132-8449'
  },
  {
    name: 'Email',
    icon: <EmailIcon />,
    info: 'contato@leffux.com.br'
  },

  {
    name: 'Localização',
    icon: <LocationIcon />,
    info: 'São Paulo | Brasil'
  }
]

export default contactList
