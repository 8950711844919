import { Box, css, styled } from '@mui/material'

export const StyledContactItem = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    gap: ${theme.spacing(2)};

    .contact-icon {
      background-color: #000;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 45px;
      width: 45px;
    }

    .contact-content {
      display: flex;
      flex-direction: column;
    }
  `}
`
