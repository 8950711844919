import { Typography, useMediaQuery } from '@mui/material'
import Divider from 'components/Divider'
import theme from 'theme'
import ServicesSection from './ServicesSection'
import { StyledAboutMe, WrapPortfolioStyled } from './style'
import { Link } from '@mui/material'
import { ReactComponent as PortfolioIcon } from 'assets/portfolio.svg'

const AboutMe = () => {
  const currentWidthDivider = useMediaQuery(theme.breakpoints.down('md')) ? 221 : 200
  return (
    <StyledAboutMe>
      <WrapPortfolioStyled>
        <Link href='https://portfolio.leffux.com.br/'>
          <PortfolioIcon />
          Portfolio
        </Link>
      </WrapPortfolioStyled>
      <div className='about-me-title'>
        <Typography variant='h1' color='white'>
          Sobre mim
        </Typography>
        <Divider width={currentWidthDivider} />
      </div>
      <Typography variant='body1' mb={1}>
        Com mais de 13 anos dedicados ao <strong>Web Design</strong>, tenho paixão pela
        criação e melhoria constante de materiais digitais. Desde meus dias como professor
        até meu destaque em agências e empresas de consultoria de desenvolvimento de
        softwares,
        <strong>
          {' '}
          busco solucionar os problemas dos usuários com layouts inovadores.
        </strong>
      </Typography>
      <Typography variant='body1' mb={1}>
        Minha expertise abrange diversas tecnologias, como{' '}
        <em>WordPress, React, Figma.</em> Recentemente, aprofundei meus conhecimentos no{' '}
        <strong>desenvolvimento de Temas e Plugins no WordPress.</strong>
      </Typography>
      <Typography variant='body1' mb={1}>
        Além do desenvolvimento, possuo habilidades em Marketing Digital, incluindo a
        <strong> criação de funis de vendas e campanhas estratégicas.</strong> Estou
        comprometido em aprender e compartilhar o que sei para evoluirmos nossos produtos
        digitais.
      </Typography>
      <Typography variant='body1' mb={8}>
        Como estratégia, gosto de usar a metodologia do <strong>Duplo Diamante</strong> em
        meus processos de design com cuidado em tudo o que faço. 😁✨
      </Typography>
      <ServicesSection />
    </StyledAboutMe>
  )
}

export default AboutMe
