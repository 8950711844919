import { Paper, css, styled } from '@mui/material'

export const StyledServiceCard = styled(Paper)`
  ${({ theme }) => css`
    border: 3px dashed #fff;
    border-radius: 16px;
    display: flex;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(2)};
    min-height: 154px;
    width: 100%;

    svg {
      min-width: 37px;
      min-height: 37px;
    }

    ${theme.breakpoints.down('lg')} {
      flex-direction: column;
    }

    ${theme.breakpoints.down('md')} {
      flex-direction: row;
    }
  `}
`
