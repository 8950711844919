import { ReactComponent as BehanceIcon } from 'assets/behance.svg'
import { ReactComponent as LinkedinIcon } from 'assets/linkedin.svg'

export const socialLinkList = [
  {
    name: 'LinkedIn',
    icon: <LinkedinIcon />,
    link: 'https://www.linkedin.com/in/alefsilva/'
  },
  {
    name: 'Behance',
    icon: <BehanceIcon />,
    link: 'https://www.behance.net/leffux'
  }
]

export const whatsappLink =
  'https://api.whatsapp.com/send?phone=5511981328449&text=Ol%C3%A1%2C%20pode%20me%20ajudar%3F'
