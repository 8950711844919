import { createTheme } from '@mui/material'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1440
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#DBDBDB',
          wordBreak: 'break-word'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#5845C2'
    },
    secondary: {
      main: '#991B1B'
    },
    gray: {
      100: '#DBDBDB',
      200: '#1D1D1D'
    },
    background: {
      default: '#FFFFFF',
      paper: '#111111'
    }
  },
  shape: {
    borderRadius: 24
  },
  typography: {
    fontFamily: "'Laila', serif",
    fontWeightRegular: 400,
    fontWeightBold: 700,
    h1: {
      fontFamily: "'Laila', serif",
      fontSize: '4rem',
      lineHeight: '5.1rem',
      fontWeight: 700
    },
    h2: {
      fontFamily: "'Laila', serif",
      fontSize: '2rem',
      lineHeight: '3rem',
      fontWeight: 700
    },
    body1: {
      fontFamily: "'Laila', serif",
      fontSize: '1.6rem',
      lineHeight: '2.7rem'
    },
    body2: {
      fontFamily: "'Laila', serif",
      fontSize: '1.6rem',
      lineHeight: '2.4rem'
    },
    caption: {
      fontFamily: "'Laila', serif",
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
      fontWeight: 700
    },
    button: {
      fontFamily: "'Laila', serif",
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: '3rem',
      color: '#2D172C',
      textTransform: 'none',
      minHeight: 62
    }
  }
})

export default theme
