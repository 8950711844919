import { Stack, Typography } from '@mui/material'
import { StyledServiceCard } from './style'

const ServiceCard = ({ icon, title, description }) => (
  <StyledServiceCard>
    {icon}
    <Stack gap={1}>
      <Typography variant='h2' color='white'>
        {title}
      </Typography>
      <Typography variant='body1'>{description}</Typography>
    </Stack>
  </StyledServiceCard>
)

export default ServiceCard
