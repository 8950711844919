import { Typography } from '@mui/material'
import serviceList from 'constants/serviceList'
import ServiceCard from '../ServiceCard'
import { StyledServices } from './style'

const ServicesSection = () => (
  <>
    <Typography variant='h1' color='white' mb={5}>
      No que trabalho!
    </Typography>
    <StyledServices>
      {/* <div className='services-first-row'>
        <ServiceCard {...serviceList[0]} />
      </div> */}
      <div className='services-second-row'>
        <ServiceCard {...serviceList[0]} />
        <ServiceCard {...serviceList[1]} />
      </div>
      <div className='services-second-row'>
        <ServiceCard {...serviceList[2]} />
        <ServiceCard {...serviceList[3]} />
      </div>
    </StyledServices>
  </>
)

export default ServicesSection
