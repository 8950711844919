import { CssBaseline, ThemeProvider } from '@mui/material'
import Home from 'Home'
import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from 'reportWebVitals'
import GlobalStyles from 'theme/globalStyles'
import theme from 'theme/index'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <CssBaseline />
    <GlobalStyles />
    <ThemeProvider theme={theme}>
      <Home />
    </ThemeProvider>
  </React.StrictMode>
)

reportWebVitals()
