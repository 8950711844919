import { Button, css, styled } from '@mui/material'

export const StyledButton = styled(Button)`
  ${({ theme }) => css`
    background-color: #fff;
    border: 3px inset;
    border-block-end-color: ${theme.palette.secondary.main};
    border-block-start-color: ${theme.palette.primary.main};
    border-left-color: ${theme.palette.gray[100]};
    border-right-color: ${theme.palette.gray[100]};
    border-radius: 26px;
    color: #2d172c;
    padding: ${theme.spacing(2, 10)};
    transition: all 0.2s ease-in;

    svg path {
      fill: #2d172c;
    }

    :hover {
      background-color: #fafafa;
      border-block-end-color: ${theme.palette.primary.main};
      border-block-start-color: ${theme.palette.secondary.main};
      text-shadow: 0 3px 0 #0000001f;
    }

    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(2, 0)};
      width: 100%;
    }
  `}
`
