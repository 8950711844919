import { Stack } from '@mui/material'
import LogoMobile from 'assets/logo-mobile.png'
import AboutMe from 'components/AboutMe'
import Sidebar from 'components/Sidebar'
import { HomeContainer, HomeContent, LogoMobileCard } from './style'

const Home = () => (
  <HomeContainer>
    <HomeContent disableGutters>
      <Stack alignItems='flex-end'>
        <Sidebar />
      </Stack>
      <LogoMobileCard>
        <img src={LogoMobile} alt='logo' />
      </LogoMobileCard>
      <AboutMe />
    </HomeContent>
  </HomeContainer>
)

export default Home
