import { IconButton, css, styled } from '@mui/material'

export const StyledIconButton = styled(IconButton)`
  ${({ theme }) => css`
    background-color: ${theme.palette.gray[200]};
    border-radius: 8px;
    height: 48px;
    width: 48px;
    transition: all 0.3s ease-in;

    :hover {
      background-color: ${theme.palette.primary.main};

      svg path {
        fill: #fff;
      }
    }
  `}
`
